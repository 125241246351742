import React from "react";
import "./testimonial.css";
import Navbar from "../../components/globalComponents/Navbar";
import Footer from "../../components/globalComponents/Footer";
import RootLayout from "../../components/globalComponents/RootLayout";

export default function Testimonials() {
  const data = [
    {
      content:
        "Naukhel made our party unforgettable! The variety of games kept everyone engaged and laughing all night long. Easy to set up and incredibly fun to play, it's become a staple for our gatherings. Highly recommend for anyone looking to spice up their events!",
      name: "Sarah L.",
    },
    {
      content:
        "As an event planner, I'm always on the lookout for interactive entertainment options. Naukhel exceeded my expectations! It's versatile, user-friendly, and offers endless hours of entertainment. My clients and their guests absolutely loved it, and it's now a must-have at all my events.",
      name: "Jason M.",
    },
    {
      content:
        "Naukhel brought our family game night to a whole new level! We had players of all ages, and everyone found something they enjoyed. The games are creative, the interface is intuitive, and the laughter was contagious. Can't wait for our next Naukhel game night!",
      name: "Emily T.",
    },
  ];
  return (
    <RootLayout>
      <div className="testimonial-grid">
        {data?.map((item, i) => (
          <figure class="snip1533">
            <figcaption>
              <blockquote>
                <p>{item.content}</p>
              </blockquote>
              <h3>{item.name}</h3>
            </figcaption>
          </figure>
        ))}
      </div>
    </RootLayout>
  );
}
