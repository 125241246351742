import * as React from "react";

function Played(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="14" height="14" rx="7" fill="#05CE91" />
      <path
        d="M10.75 6.25L7.25 9.74984L5.5 8"
        stroke="#E1F9F2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="7"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  );
}

export { Played };
