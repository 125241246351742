import { message } from "antd";
import React from "react";

export default function Testimonials() {
  const testimonials = [
    {
      message:
        "Naukhel is the life of our parties! It brings everyone together with laughter and friendly competition. A must-have for any gathering!",
      name: "Anjali",
    },
    {
      message:
        "I've never seen my friends so engaged! Naukhel's games are addictive, and the app's simplicity makes it perfect for any occasion.",
      name: "Aarav",
    },
    {
      message:
        "Naukhel is a game-changer! Literally. It transformed our boring gatherings into unforgettable memories filled with joy and laughter.",
      name: "Sabita",
    },
  ];
  return (
    <section
      className="testimonial section has-bg-color-cut illustration-section-02"
      id="testimonials"
    >
      <div className="container">
        <div className="testimonial-inner section-inner">
          <div className="section-header center-content invert-color">
            <div className="container-xs">
              <h1 className="m-0">Customer Stories: Hear Their Voices</h1>
            </div>
          </div>
          <div className="tiles-wrap">
            {testimonials?.map((item, i) => (
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                key={i}
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">— {item.message}</p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">{item.name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
