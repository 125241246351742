import React, { useState } from "react";
import "./faq.css";

export default function FAQComponent() {
  const faqs = [
    {
      question: "What is Naukhel?",
      answer: `Naukhel is an interactive party and event game application that brings
        laughter and fun to any gathering. It offers a collection of
        entertaining games designed to get everyone involved and create
        unforgettable memories.`,
    },
    {
      question: "How does Naukhel work?",
      answer: `Naukhel is hosted by the event organizer who purchases a subscription
        for the event. Players download the Naukhel app on their smartphones or
        other devices, and the host sets up the game settings, initiating rounds
        of various exciting challenges and activities for everyone to enjoy.`,
    },
    {
      question: "Do players need to download the Naukhel app to play?",
      answer: `Yes, players need to download the Naukhel app on their smartphones or
        other devices to play. Once downloaded, they can join the game and
        participate in the fun-filled activities at your event.`,
    },
    {
      question: "How can I host a game event with Naukhel?",
      answer: `Hosting a game event with Naukhel is simple! Just sign up for a
        subscription on our website, set up the game settings, and invite your
        friends or event attendees to join the fun.`,
    },
    {
      question: "Is Naukhel suitable for all ages?",
      answer: `Yes, Naukhel offers a variety of games that are suitable for players of
        all ages. However, some games may include content that is more
        appropriate for adult audiences, so it's important for the host to
        select games accordingly.`,
    },
    {
      question: "Can I customize the games and settings for my event?",
      answer: `Absolutely! As the host of the event, you have full control over
        customizing the games and settings to suit the preferences and dynamics
        of your group. Choose from a variety of game options and tailor the
        experience to make it uniquely yours.`,
    },
    {
      question: "Is the subscription for Naukhel a one-time purchase?",
      answer: `Yes, the subscription for Naukhel is a one-time purchase for each event.
        The host buys the subscription for the specific event they are hosting,
        allowing everyone to participate without the need for individual
        purchases.`,
    },
    {
      question:
        "Can I play Naukhel remotely with friends who are not physically present?",
      answer: `While Naukhel is primarily designed for in-person gatherings, some games
        may be adaptable for remote play. However, the optimal experience is
        achieved when all players are physically present at the event venue.`,
    },
    {
      question: "What kind of games are included in Naukhel?",
      answer: `Naukhel offers a diverse selection of games ranging from icebreakers and
        trivia to creative challenges and group activities. Whether you're
        looking for competitive games or collaborative tasks, there's something
        for everyone to enjoy.`,
    },
    {
      question: "How can I get support if I encounter any issues with Naukhel?",
      answer: `If you encounter any issues or have questions about Naukhel, our
        dedicated support team is here to help. Simply reach out to us through
        our website, and we'll assist you promptly to ensure that your event
        runs smoothly and everyone has a fantastic time!`,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq" id="faq">
      <h2 className="mt-0 mb-16">Frequently Asked Questions</h2>
      <div className="faq-accordion">
        {faqs?.map((item, i) => (
          <div key={i}>
            <button
              className={`accordion ${activeIndex === i ? "active" : ""}`}
              onClick={() => toggleAccordion(i)}
            >
              {item.question}
            </button>
            <div
              class="panel"
              style={{
                maxHeight: activeIndex === i ? "100px" : "0", // adjust maxHeight as needed
              }}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
