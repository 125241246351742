import * as React from "react";

function Player(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="8" fill="url(#paint0_linear_816_8727)" />
      <path
        d="M12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14Z"
        stroke="#61737F"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M5.93677 17.4994C6.55149 16.4354 7.4354 15.5519 8.49969 14.9376C9.56399 14.3234 10.7712 14 12 14C13.2289 14 14.4361 14.3234 15.5003 14.9377C16.5646 15.552 17.4485 16.4355 18.0632 17.4995"
        stroke="#61737F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_816_8727"
          x1="24"
          y1="24"
          x2="-0.552886"
          y2="23.4204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3C8D4" />
          <stop offset="1" stop-color="#EBEEF5" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export { Player };
