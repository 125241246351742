import { Typography } from "antd";
import styled from "styled-components";
import theme from "../../../theme";

const { Text } = Typography;

export const Text14 = styled(Text)`
  color: ${theme.colorGray};
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

export const Text16 = styled(Text)`
  color: ${theme.colorGray};
  font-family: Poppins;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const BoldGrayText16 = styled(Text)`
  color: ${theme.colorGray};
  font-family: Poppins;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const SiderFont = styled(Text)`
  color: ${theme.menuFontColor};
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
`;

export const BoldBlackText14 = styled(Text)`
  color: ${theme.black};
  font-family: Poppins;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
`;

export const Text20 = styled(Text)`
  color: ${theme.colorGray}
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
`;

export const BlackText16 = styled(Text)`
  color: ${theme.black};
  font-family: Poppins;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const ErrorText16 = styled(Text)`
  color: ${theme.errorRed};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.015em;
`;

export const GameNormalText = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ lh }) => `${lh}px`};
  color: ${theme.white};
`;

export const Grey900Text = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ lh }) => `${lh}px`};
  color: ${theme.grey};
`;

export const Grey600Text = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ lh }) => `${lh}px`};
  color: ${theme.colorGrey600};
`;

export const Grey700Text = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #323c54;
`;

export const Grey800Text = styled(Text)`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #20293e;
`;

export const BlueText14 = styled(Text)`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.darkblue};
`;
