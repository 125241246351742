import React from "react";
import styled from "styled-components";

const StyledButton = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${({ type, theme, filled }) =>
    filled ? "#fff" : type === "secondary" ? theme.secondary : theme.primary};
  border: 2px solid
    ${({ type, theme }) =>
      type === "secondary" ? theme.secondary : theme.primary};
  border-radius: 64px;
  padding: 16px 32px;
  background-color: ${({ type, theme, filled }) =>
    !filled
      ? "transparent"
      : type === "secondary"
      ? theme.secondary
      : theme.primary};
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export default function Button({ type, children, filled, ...rest }) {
  return (
    <StyledButton type={type} filled={filled} {...rest}>
      {children}
    </StyledButton>
  );
}
