import * as React from "react";

function Bell(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.26953 7.75006C2.26829 6.86057 2.44311 5.97963 2.78392 5.15802C3.12473 4.33641 3.62478 3.59037 4.25526 2.96292C4.88574 2.33547 5.63417 1.83901 6.45741 1.50216C7.28066 1.16531 8.16243 0.994729 9.05191 1.00025C12.7634 1.02784 15.7322 4.11288 15.7322 7.8348V8.50006C15.7322 11.8578 16.4347 13.8062 17.0534 14.8711C17.1201 14.9849 17.1555 15.1143 17.1562 15.2462C17.157 15.378 17.1229 15.5077 17.0574 15.6222C16.992 15.7367 16.8975 15.832 16.7836 15.8983C16.6696 15.9646 16.5401 15.9997 16.4083 16.0001H1.59271C1.46082 15.9997 1.33136 15.9646 1.21738 15.8983C1.10339 15.8319 1.00892 15.7367 0.9435 15.6222C0.878076 15.5076 0.844009 15.3779 0.844738 15.246C0.845467 15.1141 0.880964 14.9848 0.94765 14.871C1.56671 13.806 2.26952 11.8576 2.26952 8.50006L2.26953 7.75006Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 16V16.75C6 17.5456 6.31607 18.3087 6.87868 18.8713C7.44129 19.4339 8.20435 19.75 9 19.75C9.79565 19.75 10.5587 19.4339 11.1213 18.8713C11.6839 18.3087 12 17.5456 12 16.75V16"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export { Bell };
