import { message } from "antd";
import axios from "./http-common";
import { LOGIN_PAGE, VERIFY_EMAIL_PAGE } from "../../routes";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../constants/types";

export const userRegisterAction = (finalObj) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST });

  try {
    const { status, data } = await axios.post(
      `${process.env.REACT_APP_API}/users/register/`,
      finalObj
    );

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    message.success(
      "Signed up successfully! Please check your email for verification."
    );
    sessionStorage.setItem("gaming_project_hostInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({ type: USER_REGISTER_FAIL, payload: error });

    if (error) {
      console.log({ error });
    }
    message.error("Error while signing up!");
  }
};

export const userLoginAction =
  (finalObj, remember, navigate) => async (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST });

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/users/login/`,
        finalObj
      );

      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

      if (data.token && data?.user?.is_active) {
        sessionStorage.setItem("gaming_project_hostInfo", JSON.stringify(data));

        if (remember) {
          localStorage.setItem("gaming_project_hostInfo", JSON.stringify(data));
        }
      } else if (
        data.message === "You email is not verified, we sent verification mail "
      ) {
        navigate(VERIFY_EMAIL_PAGE);
      } else if (
        data.message === "You account is inactive please contact admins."
      ) {
        message.error(data.message);
      } else {
        message.error("Invalid credentials!");
      }
    } catch (error) {
      dispatch({ type: USER_LOGIN_FAIL, payload: error });
      message.error("Error while loggin in!");
    }
  };

export const userLogoutAction = (navigate) => async (dispatch) => {
  sessionStorage.removeItem("gaming_project_hostInfo");
  localStorage.clear();
  dispatch({ type: USER_LOGOUT });
  navigate("/");
};
