import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import SiderContents from './SiderContents';
import Header from './Header';

const { Sider, Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledSider = styled(Sider)`
  min-height: 100vh;
  background-color: #061332;
`;

export default function DashboardWrapper({ children }) {
  return (
    <StyledLayout>
      <StyledSider width='250'>
        <SiderContents />
      </StyledSider>
      <Layout>
        <Header />
        <Content style={{ background: '#f8faff' }}>{children}</Content>
      </Layout>
    </StyledLayout>
  );
}
