// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 50px;
}

.faq p{
    margin: 10px 0;
    line-height: 1.7;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #eee;
    color: var(--dark-blue);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 14px;
    font-weight: 600;
}

.accordion.active, .accordion:hover {
    background-color: var(--dark-blue);
    color: white;
  }
  
  /* Style the accordion panel. Note: hidden by default */
  .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .accordion:after {
    content: '\\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: #777;
    float: right;
    margin-left: 5px;
  }
  
  .accordion.active:after {
    content: "\\2796"; /* Unicode character for "minus" sign (-) */
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/components/FAQ/faq.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA,0EAA0E;AAC1E;IACI,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;IACf,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kCAAkC;IAClC,YAAY;EACd;;EAEA,uDAAuD;EACvD;IACE,eAAe;IACf,uBAAuB;IACvB,aAAa;IACb,gBAAgB;IAChB,oCAAoC;EACtC;;EAEA;IACE,iBAAiB,EAAE,0CAA0C;IAC7D,eAAe;IACf,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,gBAAgB,EAAE,2CAA2C;IAC7D,YAAY;EACd","sourcesContent":[".faq {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n    margin-bottom: 50px;\n}\n\n.faq p{\n    margin: 10px 0;\n    line-height: 1.7;\n}\n\n/* Style the buttons that are used to open and close the accordion panel */\n.accordion {\n    background-color: #eee;\n    color: var(--dark-blue);\n    cursor: pointer;\n    padding: 18px;\n    width: 100%;\n    text-align: left;\n    border: none;\n    outline: none;\n    transition: 0.4s;\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.accordion.active, .accordion:hover {\n    background-color: var(--dark-blue);\n    color: white;\n  }\n  \n  /* Style the accordion panel. Note: hidden by default */\n  .panel {\n    padding: 0 18px;\n    background-color: white;\n    max-height: 0;\n    overflow: hidden;\n    transition: max-height 0.2s ease-out;\n  }\n\n  .accordion:after {\n    content: '\\02795'; /* Unicode character for \"plus\" sign (+) */\n    font-size: 13px;\n    color: #777;\n    float: right;\n    margin-left: 5px;\n  }\n  \n  .accordion.active:after {\n    content: \"\\2796\"; /* Unicode character for \"minus\" sign (-) */\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
