import axiosInstance from "./axiosInstance";

const endpoint = "/core/contact-us/";

export const postMessage = (data) => {
  const options = {
    method: "post",
    url: `${endpoint}`,
    data: data,
  };
  return axiosInstance(options);
};
