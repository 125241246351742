// store.js
import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers"; // Your combined reducers

const middleware = [thunk];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware)
  // other store enhancers if any
);

const hostInfoData =
  JSON.parse(sessionStorage.getItem("gaming_project_hostInfo")) ||
  JSON.parse(localStorage.getItem("gaming_project_hostInfo"));

const intitalState = {
  hostInfo: {
    hostInfo: hostInfoData,
  },
};

const store = createStore(rootReducer, intitalState, enhancer);

export default store;
