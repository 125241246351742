import { Avatar, Col, Dropdown, Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../globalComponents/Button";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { EVENT_CREATE_PAGE } from "../../routes";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutAction } from "../../redux/actions/userActions";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
`;

const PageTitle = styled.div`
  color: #000;
  font-size: 20px;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
`;

// const BellIcon = styled.div`
//   color: ${({ theme }) => theme.bellIconColor};
//   font-size: 30px;
//   height: 35px;
//   margin: 0px 20px;
//   cursor: pointer;
// `;

const UserSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  span {
    font-size: 16px;
    color: ${({ theme }) => theme.usernameColor};
    margin-left: 10px;
  }
`;

const DropdownWrapper = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${theme.primary};
`;

const DropdownCol = styled(Col)`
  padding: 10px;
  cursor: pointer;
`;

export default function Header() {
  const { hostInfo } = useSelector((state) => state.hostInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const [title, setTitle] = useState("Dashboard");

  const capitalizeFirst = (title) => {
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  useEffect(() => {
    const routeMain = pathname.split("/")[1];
    if (routeMain) {
      const headerTitle = capitalizeFirst(routeMain);
      setTitle(headerTitle);
    } else {
      setTitle("Dashboard");
    }
  }, [pathname]);

  const handleLogout = () => {
    dispatch(userLogoutAction(navigate));
  };

  const Overlay = () => (
    <DropdownWrapper>
      <Row style={{ width: "100%" }}>
        <DropdownCol xs={24} onClick={() => handleLogout()}>
          Logout
        </DropdownCol>
      </Row>
    </DropdownWrapper>
  );

  return (
    <HeaderWrapper>
      <PageTitle>{title}</PageTitle>
      <RightContent>
        <Link to={EVENT_CREATE_PAGE}>
          <Button>Create an Event</Button>
        </Link>
        {/* <BellIcon>
          <Bell />
        </BellIcon> */}
        <Dropdown overlay={Overlay}>
          <UserSection>
            <Avatar size="large" src={hostInfo?.user?.profile_picture} />
            <span>
              {hostInfo?.user?.full_name
                ? hostInfo.user?.full_name
                : hostInfo.user?.email}{" "}
              {/* <DownOutlined /> */}
              {/* <img
                src={ArrowDown}
                alt='arrowdown-icon'
                style={{ marginLeft: '10px' }}
              /> */}
            </span>
          </UserSection>
        </Dropdown>
      </RightContent>
    </HeaderWrapper>
  );
}
