import {
  CLEAR_BINGO_SETTINGS,
  GET_BINGO_CATEGORIES_FAIL,
  GET_BINGO_CATEGORIES_REQUEST,
  GET_BINGO_CATEGORIES_SUCCESS,
  GET_BINGO_SETTINGS_FAIL,
  GET_BINGO_SETTINGS_REQUEST,
  GET_BINGO_SETTINGS_SUCCESS,
  SAVE_BINGO_SETTINGS_FAIL,
  SAVE_BINGO_SETTINGS_REQUEST,
  SAVE_BINGO_SETTINGS_SUCCESS,
} from '../constants/types';

export const getBingoCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BINGO_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case GET_BINGO_CATEGORIES_SUCCESS:
      return { ...state, loading: false, bingoCategories: action.payload };

    case GET_BINGO_CATEGORIES_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getBingoSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BINGO_SETTINGS_REQUEST:
      return { ...state, loading: true, bingoSettings: {}, error: null };

    case GET_BINGO_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        bingoSettings: action.payload,
        error: null,
      };

    case GET_BINGO_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        bingoSettings: {},
        error: action.payload,
      };

    case CLEAR_BINGO_SETTINGS:
      return { ...state, loading: false, bingoSettings: {} };

    default:
      return state;
  }
};

export const saveBingoSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_BINGO_SETTINGS_REQUEST:
      return { ...state, loading: true };

    case SAVE_BINGO_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        saveBingoSettings: action.payload,
      };

    case SAVE_BINGO_SETTINGS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
