import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import {
  DASHBOARD_PAGE,
  LANDING_PAGE,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
} from "../../routes";
import { CONTACT } from "../../data";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer_content">
          <div className="first_section">
            <h3 className="footer_headings">About Us</h3>
            <Link to={LANDING_PAGE}>
              <img src="/logo.png" alt="naukhel" className="naukhel-logo" />
            </Link>
            <p>
              Naukhel is an interactive party and event game application that
              brings laughter and fun to any gathering. It offers a collection
              of entertaining games designed to get everyone involved and create
              unforgettable memories.
            </p>
          </div>
          <div className="second_section">
            <h3 className="footer_headings">Useful links</h3>
            <ul>
              <li>
                <a href="/#faq">FAQ</a>
              </li>
              <li>
                <a href="/#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="/#contact">Contact</a>
              </li>
              <li>
                <Link to={TERMS_AND_CONDITIONS}>Terms & Conditions</Link>
              </li>
              <li>
                <Link to={PRIVACY_POLICY}>Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="third_section">
            <h3 className="footer_headings">Contact</h3>
            <ul>
              <li>
                <MailOutlined />
                <a href={`mailto:${CONTACT?.email}`}>{CONTACT?.email}</a>
              </li>
              <li>
                <EnvironmentOutlined />
                <a
                  href={`https://maps.app.goo.gl/K9KNWwLwpFQQCHct5`}
                  target="_blank"
                >
                  {CONTACT?.address}
                </a>
              </li>

              <li>
                <PhoneOutlined />
                <a href={`tel:${CONTACT?.phone}`}>{CONTACT?.phone}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer_txt">
        <p style={{ marginBottom: 0 }}>Copyright © 2022 | NauKhel.com</p>
      </div>
    </footer>
  );
}

export default Footer;
