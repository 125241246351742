import * as React from "react";

function Clock(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.5V9"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.8971 11.25L10 9"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.73438 6.34839H0.984375V2.59839"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16635 14.8336C5.32014 15.9874 6.79015 16.7732 8.39049 17.0915C9.99083 17.4098 11.6496 17.2464 13.1571 16.622C14.6646 15.9976 15.9531 14.9402 16.8596 13.5835C17.7661 12.2268 18.25 10.6317 18.25 9C18.25 7.36831 17.7661 5.77325 16.8596 4.41655C15.9531 3.05984 14.6646 2.00242 13.1571 1.378C11.6496 0.753575 9.99083 0.590197 8.39049 0.908525C6.79015 1.22685 5.32014 2.01259 4.16635 3.16637L0.984375 6.34835"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export { Clock };
