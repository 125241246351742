const theme = {
  primary: "#FF307E",
  secondary: "#1E5DFC",
  darkblue: "#19437A",
  success: "#05CE91",
  error: "#FF6161",
  tertary: "#B66DFF",
  warning: "#FFAD49",
  menuFontColor: "#767F94",
  bellIconColor: "#61737f",
  usernameColor: "#515151",
  cardBorderColor: "#ff6161",
  colorGray: "#616A7F",
  colorDarkGray: "#121929",
  colorGrey600: "#121929",
  black: "#000000",
  errorRed: "#CC4E4E",
  white: "#FFFFFF",
};

export default theme;
