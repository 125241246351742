import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function RootLayout({ children }) {
  return (
    <div>
      <Navbar />
      <div className="container-parent">
        <div className="container">{children}</div>
      </div>
      <Footer />
    </div>
  );
}
