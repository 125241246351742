import React from "react";

export default function Features() {
  return (
    <section className="features-tiles section">
      <div className="container">
        <div className="features-tiles-inner section-inner">
          <div className="tiles-wrap">
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-container=".tiles-wrap"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img src="/images/feature-tile-icon-01.svg" alt="trivia" />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Exciting Trivia</h4>
                  <p className="m-0 text-sm">
                    Uncover fascinating facts, challenge your mind, expand your
                    knowledge, and impress everyone with what you discover!
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-container=".tiles-wrap"
              data-reveal-delay="100"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src="/images/feature-tile-icon-02.svg"
                      alt="Feature tile icon 02"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Engaging Bingo</h4>
                  <p className="m-0 text-sm">
                    Play, connect, laugh, and win! Experience the thrill of each
                    game and create unforgettable memories with friends.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-container=".tiles-wrap"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src="/images/feature-tile-icon-03.svg"
                      alt="Feature tile icon 03"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Mystery Game</h4>
                  <p className="m-0 text-sm">
                    Dive into intrigue, solve puzzles, uncover secrets, and
                    embark on an adventure that keeps you guessing till the end!
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-container=".tiles-wrap"
              data-reveal-delay="300"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <img
                      src="/images/feature-tile-icon-04.svg"
                      alt="Feature tile icon 04"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Event-Based Play</h4>
                  <p className="m-0 text-sm">
                    Experience themed challenges, join the fun, engage with
                    others, and make every moment of gameplay unforgettable and
                    exciting!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
