// LOGIN CONSTANTS
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

// REGISTER CONSTANTS
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

// LOGOUT CONSTANTS
export const USER_LOGOUT = 'USER_LOGOUT';

// PACKAGES CONSTANTS
export const GET_PACKAGES_REQUEST = 'GET_PACKAGES_REQUEST';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAIL = 'GET_PACKAGES_FAIL';

// PAYMENT METHODS CONSTANTS
export const GET_PAYMENT_METHODS_REQUEST = 'GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';

// SUBSCRIPTIONS CONSTANTS
export const POST_SUBSCRIPTION_REQUEST = 'POST_SUBSCRIPTION_REQUEST';
export const POST_SUBSCRIPTION_SUCCESS = 'POST_SUBSCRIPTION_SUCCESS';
export const POST_SUBSCRIPTION_FAIL = 'POST_SUBSCRIPTION_FAIL';

export const GET_PAST_SUBSCRIPTIONS_REQUEST = 'GET_PAST_SUBSCRIPTIONS_REQUEST';
export const GET_PAST_SUBSCRIPTIONS_SUCCESS = 'GET_PAST_SUBSCRIPTIONS_SUCCESS';
export const GET_PAST_SUBSCRIPTIONS_FAIL = 'GET_PAST_SUBSCRIPTIONS_FAIL';

export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_FAIL = 'GET_SUBSCRIPTIONS_FAIL';

export const GET_UPCOMING_SUBSCRIPTIONS_REQUEST =
  'GET_UPCOMING_SUBSCRIPTIONS_REQUEST';
export const GET_UPCOMING_SUBSCRIPTIONS_SUCCESS =
  'GET_UPCOMING_SUBSCRIPTIONS_SUCCESS';
export const GET_UPCOMING_SUBSCRIPTIONS_FAIL =
  'GET_UPCOMING_SUBSCRIPTIONS_FAIL';

export const GET_SUBSCRIPTION_DATA_REQUEST = 'GET_SUBSCRIPTION_DATA_REQUEST';
export const GET_SUBSCRIPTION_DATA_SUCCESS = 'GET_SUBSCRIPTION_DATA_SUCCESS';
export const GET_SUBSCRIPTION_DATA_FAIL = 'GET_SUBSCRIPTION_DATA_FAIL';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAIL = 'UPDATE_SUBSCRIPTION_FAIL';

// GAMES CONSTANTS
export const GET_GAMES_REQUEST = 'GET_GAMES_REQUEST';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';

export const GET_GAME_DATA_REQUEST = 'GET_GAME_DATA_REQUEST';
export const GET_GAME_DATA_SUCCESS = 'GET_GAME_DATA_SUCCESS';
export const GET_GAME_DATA_FAIL = 'GET_GAME_DATA_FAIL';

// RAMAILO CONSTANTS
export const GET_RAMAILO_CATEGORIES_REQUEST = 'GET_RAMAILO_CATEGORIES_REQUEST';
export const GET_RAMAILO_CATEGORIES_SUCCESS = 'GET_RAMAILO_CATEGORIES_SUCCESS';
export const GET_RAMAILO_CATEGORIES_FAIL = 'GET_RAMAILO_CATEGORIES_FAIL';

export const GET_RAMAILO_PAST_EVENTS_REQUEST =
  'GET_RAMAILO_PAST_EVENTS_REQUEST';
export const GET_RAMAILO_PAST_EVENTS_SUCCESS =
  'GET_RAMAILO_PAST_EVENTS_SUCCESS';
export const GET_RAMAILO_PAST_EVENTS_FAIL = 'GET_RAMAILO_PAST_EVENTS_FAIL';

export const GET_RAMAILO_SETTINGS_REQUEST = 'GET_RAMAILO_SETTINGS_REQUEST';
export const GET_RAMAILO_SETTINGS_SUCCESS = 'GET_RAMAILO_SETTINGS_SUCCESS';
export const GET_RAMAILO_SETTINGS_FAIL = 'GET_RAMAILO_SETTINGS_FAIL';

export const SAVE_RAMAILO_SETTINGS_REQUEST = 'SAVE_RAMAILO_SETTINGS_REQUEST';
export const SAVE_RAMAILO_SETTINGS_SUCCESS = 'SAVE_RAMAILO_SETTINGS_SUCCESS';
export const SAVE_RAMAILO_SETTINGS_FAIL = 'SAVE_RAMAILO_SETTINGS_FAIL';

// NATAKKHEL CONSTANTS
export const GET_NATAKKHEL_CATEGORIES_REQUEST =
  'GET_NATAKKHEL_CATEGORIES_REQUEST';
export const GET_NATAKKHEL_CATEGORIES_SUCCESS =
  'GET_NATAKKHEL_CATEGORIES_SUCCESS';
export const GET_NATAKKHEL_CATEGORIES_FAIL = 'GET_NATAKKHEL_CATEGORIES_FAIL';

export const GET_NATAKKHEL_SETTINGS_REQUEST = 'GET_NATAKKHEL_SETTINGS_REQUEST';
export const GET_NATAKKHEL_SETTINGS_SUCCESS = 'GET_NATAKKHEL_SETTINGS_SUCCESS';
export const GET_NATAKKHEL_SETTINGS_FAIL = 'GET_NATAKKHEL_SETTINGS_FAIL';

export const SAVE_NATAKKHEL_SETTINGS_REQUEST =
  'SAVE_NATAKKHEL_SETTINGS_REQUEST';
export const SAVE_NATAKKHEL_SETTINGS_SUCCESS =
  'SAVE_NATAKKHEL_SETTINGS_SUCCESS';
export const SAVE_NATAKKHEL_SETTINGS_FAIL = 'SAVE_NATAKKHEL_SETTINGS_FAIL';

// BINGO CONSTANTS
export const GET_BINGO_CATEGORIES_REQUEST = 'GET_BINGO_CATEGORIES_REQUEST';
export const GET_BINGO_CATEGORIES_SUCCESS = 'GET_BINGO_CATEGORIES_SUCCESS';
export const GET_BINGO_CATEGORIES_FAIL = 'GET_BINGO_CATEGORIES_FAIL';

export const GET_BINGO_SETTINGS_REQUEST = 'GET_BINGO_SETTINGS_REQUEST';
export const GET_BINGO_SETTINGS_SUCCESS = 'GET_BINGO_SETTINGS_SUCCESS';
export const GET_BINGO_SETTINGS_FAIL = 'GET_BINGO_SETTINGS_FAIL';
export const CLEAR_BINGO_SETTINGS = 'CLEAR_BINGO_SETTINGS';

export const SAVE_BINGO_SETTINGS_REQUEST = 'SAVE_BINGO_SETTINGS_REQUEST';
export const SAVE_BINGO_SETTINGS_SUCCESS = 'SAVE_BINGO_SETTINGS_SUCCESS';
export const SAVE_BINGO_SETTINGS_FAIL = 'SAVE_BINGO_SETTINGS_FAIL';
