import React from "react";
import FAQComponent from "../../components/FAQ/faq";

export default function FAQ() {
  return (
    <div className="container">
      <FAQComponent />
    </div>
  );
}
