import React from "react";
import styled, { css } from "styled-components";
import { Button } from "antd";

const sharedButtonStyles = css`
  width: ${({ auto }) => (auto ? "auto" : "100%")};
  max-width: "384px";
  height: 56px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ type, theme, filled }) =>
    filled ? "#fff" : type === "secondary" ? theme.secondary : theme.primary};
  border: 2px solid
    ${({ type, theme }) =>
      type === "secondary"
        ? theme.secondary
        : type === "blue"
        ? theme.darkblue
        : theme.primary};
  border-radius: 64px;
  padding: 16px 32px;
  background-color: ${({ type, theme, filled }) =>
    !filled
      ? "transparent"
      : type === "secondary"
      ? theme.secondary
      : type === "blue"
      ? theme.darkblue
      : theme.primary};
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const MainButton = styled(Button)`
  ${sharedButtonStyles}

  &:hover {
    ${sharedButtonStyles};
    color: ${({ type, theme, filled }) =>
      type === "blue" ? theme.darkblue : ""} !important;
    border-color: ${({ type, theme, filled }) =>
      type === "blue" ? theme.darkblue : ""} !important;
    background-color: ${({ type, theme, filled }) =>
      type === "blue" ? "white" : ""} !important;
  }

  &:focus {
    ${sharedButtonStyles}
  }

  &:disabled {
    ${sharedButtonStyles}
    opacity: 0.4;
    cursor: not-allowed !important;
    &:hover {
      ${sharedButtonStyles}
      opacity: 0.4;
    }
    &:focus {
      ${sharedButtonStyles}
      opacity: 0.4;
    }
  }
`;

export default function CustomButton({ type, children, filled, ...rest }) {
  return (
    <MainButton type={type} filled={filled} {...rest}>
      {children}
    </MainButton>
  );
}
