import React from "react";
import Footer from "../../components/globalComponents/Footer";
import Navbar from "../../components/globalComponents/Navbar";
import "./landing-page.css";
import Testimonials from "./Testimonials";
import Roadmap from "./Roadmap";
import HowItWorks from "./HowItWorks";
import Features from "./Features";
import HeroSection from "./HeroSection";
import ContactForm from "../../components/globalComponents/ContactForm";
import FAQ from "./Faq";

export default function LandingPage() {
  return (
    <div>
      <Navbar />
      <div className="body-wrap">
        <main className="site-content">
          <HeroSection />
          <Features />
          <HowItWorks />
          <Testimonials />
          <FAQ />
          <Roadmap />
          <ContactForm />
        </main>
      </div>
      <Footer />
    </div>
  );
}
