import React from "react";
import { LOGIN_PAGE } from "../../routes";

export default function HeroSection() {
  return (
    <section className="hero section has-bg-color invert-color">
      <div className="container">
        <div className="hero-inner section-inner">
          <div className="split-wrap">
            <div className="split-item">
              <div className="hero-content split-item-content center-content-mobile reveal-from-top">
                <h1 className="mt-0 mb-16">Join the Fun with Naukhel!</h1>
                <p className="mt-0 mb-32">
                  Immerse yourself in the electrifying atmosphere of trivia
                  challenges, the thrill of bingo anticipation, and the joy of
                  other engaging games, all awaiting you at your upcoming event.
                </p>
                <div className="button-group">
                  <a
                    className="button button-primary button-wide-mobile"
                    href={LOGIN_PAGE}
                  >
                    Get Started
                  </a>
                  <a
                    className="button button-dark button-wide-mobile"
                    href={LOGIN_PAGE}
                  >
                    Host an Event
                  </a>
                </div>
              </div>
              <div className="hero-figure split-item-image split-item-image-fill illustration-element-01 reveal-from-bottom">
                <img
                  src="/images/naukhel-banner.webp"
                  alt="naukhel"
                  width="528"
                  height="396"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
