import { combineReducers } from 'redux';
import {
  getBingoCategoriesReducer,
  getBingoSettingsReducer,
  saveBingoSettingsReducer,
} from './bingoReducers';
import { getGameDataReducer, getGamesReducer } from './gamesReducers';
import {
  getNatakkhelCategoriesReducer,
  getNatakkhelSettingsReducer,
  saveNatakkhelSettingsReducer,
} from './natakkhelReducers';
import { getPackagesReducer } from './packagesReducers';
import { getPaymentMethodsReducer } from './paymentReducers';
import {
  getRamailoCategoriesReducer,
  getRamailoPastEventsReducer,
  getRamailoSettingsReducer,
  saveRamailoSettingsReducer,
} from './ramailoReducers';
import {
  createEventReducer,
  getPastSubscriptionsReducer,
  getSubscriptionDataReducer,
  getSubscriptionsReducer,
  getUpcomingSubscriptionsReducer,
  updateSubscriptionDataReducer,
} from './subscriptionsReducers';
import { userLoginReducer, userRegisterReducer } from './userReducers';

export default combineReducers({
  regusterData: userRegisterReducer,
  hostInfo: userLoginReducer,
  packagesList: getPackagesReducer,
  paymentMethodList: getPaymentMethodsReducer,
  createEvent: createEventReducer,
  subscriptions: getSubscriptionsReducer,
  upcomingSubscriptions: getUpcomingSubscriptionsReducer,
  pastSubscriptions: getPastSubscriptionsReducer,
  subscriptionData: getSubscriptionDataReducer,
  updateSubscription: updateSubscriptionDataReducer,
  games: getGamesReducer,
  gameData: getGameDataReducer,
  natakkhelCategories: getNatakkhelCategoriesReducer,
  natakkhelSettings: getNatakkhelSettingsReducer,
  saveNatakkhelSettings: saveNatakkhelSettingsReducer,
  bingoCategories: getBingoCategoriesReducer,
  bingoSettings: getBingoSettingsReducer,
  saveBingoSettings: saveBingoSettingsReducer,
  ramailoCategories: getRamailoCategoriesReducer,
  ramailoPastEvents: getRamailoPastEventsReducer,
  ramailoSettings: getRamailoSettingsReducer,
  saveRamailoSettings: saveRamailoSettingsReducer,
});
