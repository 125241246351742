import * as React from "react";

function Now(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="14" height="14" rx="7" fill="#1E5DFC" />
      <path
        d="M11.1296 7.78633L6.63037 5.03668C6.59247 5.01352 6.54909 5.00088 6.50468 5.00004C6.46028 4.99921 6.41645 5.01023 6.37771 5.03195C6.33897 5.05368 6.30672 5.08533 6.28428 5.12365C6.26183 5.16197 6.25 5.20559 6.25 5.25V10.7493C6.25 10.7937 6.26183 10.8373 6.28428 10.8756C6.30672 10.914 6.33897 10.9456 6.37771 10.9673C6.41645 10.9891 6.46028 11.0001 6.50468 10.9992C6.54909 10.9984 6.59247 10.9858 6.63037 10.9626L11.1296 8.21296C11.1662 8.19063 11.1964 8.15929 11.2173 8.12193C11.2383 8.08458 11.2493 8.04247 11.2493 7.99964C11.2493 7.95682 11.2383 7.91471 11.2173 7.87736C11.1964 7.84 11.1662 7.80866 11.1296 7.78633Z"
        stroke="#E9EFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="7"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  );
}

export { Now };
