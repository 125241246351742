import * as React from "react";

function GridFour(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.125 1.125H1.875C1.46079 1.125 1.125 1.46079 1.125 1.875V16.125C1.125 16.5392 1.46079 16.875 1.875 16.875H16.125C16.5392 16.875 16.875 16.5392 16.875 16.125V1.875C16.875 1.46079 16.5392 1.125 16.125 1.125Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 1.125V16.875"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.875 9H1.125"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export { GridFour };
