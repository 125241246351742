import * as React from "react";

function CreditCard(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 1.25H2C1.58579 1.25 1.25 1.58579 1.25 2V14C1.25 14.4142 1.58579 14.75 2 14.75H20C20.4142 14.75 20.75 14.4142 20.75 14V2C20.75 1.58579 20.4142 1.25 20 1.25Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.749 11.75H17.749"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.249 11.75H11.749"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.24902 5.07996H20.749"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export { CreditCard };
