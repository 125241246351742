import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/images/logo.png";
import { Menu } from "antd";
import {
  Clock,
  Compass,
  CreditCard,
  GridFour,
  Question,
} from "../../assets/icons";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { SiderFont } from "../../components/custom/Typography/DefaultText";
import theme from "../../theme";
import { DASHBOARD_PAGE } from "../../routes";

const LogoWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align: center;
  img {
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
  }
`;
const MenuWrapper = styled.div`
  .ant-menu {
    background-color: transparent;
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    &-active {
      background-color: transparent;
    }
  }
  .ant-menu-item .ant-menu-title-content {
    color: ${({ theme }) => theme.menuFontColor};
    font-weight: bold;
    font-size: 14px;
    margin-left: 20px;
  }
  .ant-menu-item-icon {
    color: ${({ theme }) => theme.menuFontColor};
    font-size: 23px;
    display: flex;
    align-items: center;
  }
  .ant-menu-vertical {
    border: none;
  }
`;

export default function SiderContents() {
  const [pathNameKey, setPathNameKey] = useState("dashboard");

  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const mainPath = pathname.split("/");
    setPathNameKey(mainPath[1]);
  }, [location]);

  const menuContents = [
    {
      name: "Dashboard",
      key: "dashboard",
      icon: <GridFour />,
      url: "/dashboard",
    },
    {
      name: "History",
      key: "history",
      icon: <Clock />,
      url: "/history",
    },
    {
      name: "Explore",
      key: "explore",
      icon: <Compass />,
      url: "/explore",
    },
    {
      name: "Subscription",
      key: "subscription",
      icon: <CreditCard />,
      url: "/subscription",
    },
    {
      name: "Help and Support",
      key: "help",
      icon: <Question />,
      url: "/help",
    },
  ];

  const Navigation = () => {
    return (
      <MenuWrapper>
        <Menu>
          {menuContents.map(({ name, icon, key, url }, idx) => (
            <Menu.Item
              icon={
                <span
                  style={{
                    color:
                      pathNameKey.toLowerCase() === key
                        ? theme.primary
                        : theme.menuFontColor,
                  }}
                >
                  {icon}
                </span>
              }
              key={idx}
              style={{
                borderRight:
                  pathNameKey.toLowerCase() === key
                    ? `8px solid ${theme.primary}`
                    : null,
              }}
            >
              <Link to={url}>
                <SiderFont
                  style={{
                    color:
                      pathNameKey.toLowerCase() === key
                        ? theme.primary
                        : theme.menuFontColor,
                  }}
                >
                  {name}
                </SiderFont>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </MenuWrapper>
    );
  };

  return (
    <div>
      <LogoWrapper>
        <Link to={DASHBOARD_PAGE}>
          <img
            src={logo}
            alt="naukhel"
            style={{ width: "110px", height: "120px" }}
          />
        </Link>
      </LogoWrapper>
      <Navigation />
    </div>
  );
}
