import React from "react";

export default function HowItWorks() {
  const steps = [
    { title: "Subscribe", img: "/images/features-tabs-icon-01.svg" },
    { title: "Invite", img: "/images/features-tabs-icon-02.svg" },
    { title: "Start", img: "/images/features-tabs-icon-03.svg" },
    { title: "Enjoy", img: "/images/features-tabs-icon-04.svg" },
  ];
  return (
    <section
      className="features-tabs section center-content has-bottom-divider"
      id="how-it-works"
    >
      <div className="container">
        <div className="features-tabs-inner section-inner has-top-divider">
          <div className="section-header center-content">
            <div className="container-xs">
              <h2 className="mt-0 mb-16">How it works?</h2>
              <p className="m-0">
                Download Naukhel, join a game, and enjoy interactive fun with
                friends. It's that simple to create unforgettable moments!
              </p>
            </div>
          </div>
          <div className="tabs">
            <ul className="tab-list list-reset mb-0">
              {steps?.map((item) => (
                <li
                  className="tab is-active"
                  role="tab"
                  aria-controls="tab-a"
                  key={item.title}
                >
                  <div className="features-tabs-tab-image mb-12">
                    <img src={item.img} alt="feature" width="56" height="56" />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    {item.title}
                  </div>
                </li>
              ))}
            </ul>
            <div id="tab-a" className="tab-panel" role="tabpanel">
              <img
                className="has-shadow"
                src="/images/features-tabs-image.png"
                alt="Features tabs image 01"
                width="896"
                height="504"
              />
            </div>
            <div id="tab-b" className="tab-panel" role="tabpanel">
              <img
                className="has-shadow"
                src="/images/features-tabs-image.png"
                alt="Features tabs image 02"
                width="896"
                height="504"
              />
            </div>
            <div id="tab-c" className="tab-panel" role="tabpanel">
              <img
                className="has-shadow"
                src="/images/features-tabs-image.png"
                alt="Features tabs image 03"
                width="896"
                height="504"
              />
            </div>
            <div id="tab-d" className="tab-panel" role="tabpanel">
              <img
                className="has-shadow"
                src="/images/features-tabs-image.png"
                alt="Features tabs image 04"
                width="896"
                height="504"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
