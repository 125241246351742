import {
  GET_GAMES_FAIL,
  GET_GAMES_REQUEST,
  GET_GAMES_SUCCESS,
  GET_GAME_DATA_FAIL,
  GET_GAME_DATA_REQUEST,
  GET_GAME_DATA_SUCCESS,
} from "../constants/types";

export const getGamesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GAMES_REQUEST:
      return { ...state, loading: true };

    case GET_GAMES_SUCCESS:
      return { ...state, loading: false, games: action.payload };

    case GET_GAMES_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getGameDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GAME_DATA_REQUEST:
      return { ...state, loading: true };

    case GET_GAME_DATA_SUCCESS:
      return { ...state, loading: false, gameData: action.payload };

    case GET_GAME_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
