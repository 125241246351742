import React from "react";

export default function Roadmap() {
  const timeline = [
    {
      date: "2022 August",
      event: "Initial idea develop",
    },
    {
      date: "2022 October",
      event: "Completed Initial user interface design",
    },
    {
      date: "2022 Dec",
      event: "Development started",
    },
    {
      date: "2023 Dec",
      event: "Conducted closed beta testing with selected user groups",
    },
    {
      date: "2024 July",
      event: "Launched public beta version for user feedback collection",
    },
    {
      date: "2024 Sept",
      event: "Implemented user feedback and performance enhancements",
    },
  ];
  return (
    <section className="roadmap section has-top-divider">
      <div className="container">
        <div className="roadmap-inner section-inner">
          <div className="section-header center-content">
            <div className="container-xs">
              <h2 className="mt-0 mb-16">Product roadmap</h2>
              <p className="mb-0">
                Discover our journey from concept to launch, detailing key
                milestones and exciting developments along the way.
              </p>
            </div>
          </div>
          <div className="timeline">
            <div className="timeline-wrap">
              {timeline?.map((item, i) => (
                <div className="timeline-item" key={item.date + i}>
                  <div className="timeline-item-inner">
                    <div className="timeline-item-header text-color-low tt-u mb-4 reveal-fade">
                      {item.date}
                    </div>
                    <div className="timeline-item-content fw-700 h4 m-0 reveal-from-side">
                      {item.event}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
