import { Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  DASHBOARD_PAGE,
  FAQ_PAGE,
  LANDING_PAGE,
  LOGIN_PAGE,
  REGISTER_PAGE,
  TESTIMONIALS_PAGE,
} from "../../routes";
import { AlignedCol, CenteredCol } from "../custom/Other/CustomCol";
import Logo from "../../assets/icons/logo.png";
import CustomButton from "./CustomButton";
import { BlackText16 } from "../custom/Typography/DefaultText";
import "./Navbar.css";
import { AlignCenterOutlined } from "@ant-design/icons";

function Navbar() {
  const handleHamburger = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  };
  return (
    <div className="header">
      <div className="topnav container" id="myTopnav">
        <a href={LANDING_PAGE}>
          <img src={Logo} alt="Naukhel" className="logo" />
        </a>
        <a href="/#how-it-works">How to Play</a>
        <a href={"/#testimonials"}>Testimonials</a>
        <a href={"/#faq"}>FAQ</a>
        <a href={"/#contact"}>Contact</a>
        <a href={LOGIN_PAGE} className="login-btn">
          Login
        </a>
        <a
          href="javascript:void(0);"
          className="icon"
          onClick={handleHamburger}
        >
          <AlignCenterOutlined />
        </a>
      </div>
    </div>
    // <Row style={{ width: "100%", height: "104px" }}>
    //   <AlignedCol xs={4}>
    //     <Link to={DASHBOARD_PAGE}>
    //       <img
    //         src={Logo}
    //         alt="logo"
    //         style={{ width: "80px", height: "90px" }}
    //       />
    //     </Link>
    //   </AlignedCol>
    //   <AlignedCol xs={14}>
    //     <Row style={{ width: "100%" }} justify="space-around">
    //       <CenteredCol>
    //         <BlackText16>Pricing</BlackText16>
    //       </CenteredCol>
    //       <CenteredCol>
    //         <Link to={FAQ_PAGE}>
    //           <BlackText16>FAQ</BlackText16>
    //         </Link>
    //       </CenteredCol>
    //       <CenteredCol>
    //         <BlackText16>Contact</BlackText16>
    //       </CenteredCol>
    //       <CenteredCol>
    //         <Link to={TESTIMONIALS_PAGE}>
    //           <BlackText16>Testimonials</BlackText16>
    //         </Link>
    //       </CenteredCol>
    //     </Row>
    //   </AlignedCol>
    //   <CenteredCol xs={6}>
    //     <Link to={LOGIN_PAGE}>
    //       <CustomButton filled="true" style={{ width: "103px" }}>
    //         Login
    //       </CustomButton>
    //     </Link>
    //     <Link to={REGISTER_PAGE} style={{ paddingLeft: "20px" }}>
    //       <CustomButton style={{ width: "103px" }}>Register</CustomButton>
    //     </Link>
    //   </CenteredCol>
    // </Row>
  );
}

export default Navbar;
