import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBf759mcv0bINtQKmNphBBd5Ze7tONMFTc",
  authDomain: "naukhel-2154d.firebaseapp.com",
  projectId: "naukhel-2154d",
  storageBucket: "naukhel-2154d.appspot.com",
  messagingSenderId: "702222566251",
  appId: "1:702222566251:web:c64757243884815f70822e",
  measurementId: "G-V59CVDX2F8",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

export const auth = firebase.auth();
