import {
  GET_PAYMENT_METHODS_FAIL,
  GET_PAYMENT_METHODS_REQUEST,
  GET_PAYMENT_METHODS_SUCCESS,
} from "../constants/types";

export const getPaymentMethodsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS_REQUEST:
      return { ...state, loading: true };

    case GET_PAYMENT_METHODS_SUCCESS:
      return { ...state, loading: false, paymentMethodList: action.payload };

    case GET_PAYMENT_METHODS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
