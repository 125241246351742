import { message } from "antd";
import React, { useState } from "react";
import { postMessage } from "../../services/contact";

export default function ContactForm() {
  const [submitting, setSubmitting] = useState(false);
  const emptyForm = {
    name: "",
    email: "",
    message: "",
  };
  const [form, setForm] = useState(emptyForm);

  const handleSubmit = async (e) => {
    console.log("click");
    e.preventDefault();
    let isValid = true;
    if (!form?.name) {
      message.error("Name is required!");
      isValid = false;
    }

    if (!form?.email) {
      message.error("Email is required!");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form?.email)) {
      message.error("Email is invalid!");
      isValid = false;
    }

    if (!form?.message) {
      message.error("Message is required!");
      isValid = false;
    }

    if (isValid) {
      try {
        setSubmitting(true);
        await postMessage(form);
        message.success("Message submitted successfully.");
        setForm(emptyForm);
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        message.error(error?.message || "Something went wrong!");
        setSubmitting(false);
      }
    }
  };
  return (
    <div className="container" id="contact">
      <div className="contact-form">
        <h2 className="mt-0 mb-16">Keep in touch</h2>
        <p>
          Stay connected with us to receive the latest updates, insightful
          content, and exclusive offers delivered straight to your inbox.
        </p>
        <form>
          <div className="input-box">
            <input
              type="text"
              placeholder="Enter your name *"
              required
              value={form?.name}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
            />
          </div>
          <div className="input-box">
            <input
              type="email"
              placeholder="Enter your email *"
              required
              value={form?.email}
              onChange={(e) => {
                setForm({ ...form, email: e.target.value });
              }}
            />
          </div>
          <div className="textarea-input-box">
            <textarea
              placeholder="Write your message... *"
              required
              value={form?.message}
              onChange={(e) => {
                setForm({ ...form, message: e.target.value });
              }}
            />
          </div>
          <button
            type="submit"
            className="send-btn"
            onClick={handleSubmit}
            disabled={submitting}
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
}
