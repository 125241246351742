import {
  GET_NATAKKHEL_CATEGORIES_FAIL,
  GET_NATAKKHEL_CATEGORIES_REQUEST,
  GET_NATAKKHEL_CATEGORIES_SUCCESS,
  GET_NATAKKHEL_SETTINGS_FAIL,
  GET_NATAKKHEL_SETTINGS_REQUEST,
  GET_NATAKKHEL_SETTINGS_SUCCESS,
  SAVE_NATAKKHEL_SETTINGS_FAIL,
  SAVE_NATAKKHEL_SETTINGS_REQUEST,
  SAVE_NATAKKHEL_SETTINGS_SUCCESS,
} from "../constants/types";

export const getNatakkhelCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NATAKKHEL_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case GET_NATAKKHEL_CATEGORIES_SUCCESS:
      return { ...state, loading: false, natakkhelCategories: action.payload };

    case GET_NATAKKHEL_CATEGORIES_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getNatakkhelSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NATAKKHEL_SETTINGS_REQUEST:
      return { ...state, loading: true };

    case GET_NATAKKHEL_SETTINGS_SUCCESS:
      return { ...state, loading: false, natakkhelSettings: action.payload };

    case GET_NATAKKHEL_SETTINGS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const saveNatakkhelSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_NATAKKHEL_SETTINGS_REQUEST:
      return { ...state, loading: true };

    case SAVE_NATAKKHEL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        saveNatakkhelSettings: action.payload,
      };

    case SAVE_NATAKKHEL_SETTINGS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
