import {
  GET_RAMAILO_CATEGORIES_FAIL,
  GET_RAMAILO_CATEGORIES_REQUEST,
  GET_RAMAILO_CATEGORIES_SUCCESS,
  GET_RAMAILO_PAST_EVENTS_FAIL,
  GET_RAMAILO_PAST_EVENTS_REQUEST,
  GET_RAMAILO_PAST_EVENTS_SUCCESS,
  GET_RAMAILO_SETTINGS_FAIL,
  GET_RAMAILO_SETTINGS_REQUEST,
  GET_RAMAILO_SETTINGS_SUCCESS,
  SAVE_RAMAILO_SETTINGS_FAIL,
  SAVE_RAMAILO_SETTINGS_REQUEST,
  SAVE_RAMAILO_SETTINGS_SUCCESS,
} from '../constants/types';

export const getRamailoCategoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RAMAILO_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case GET_RAMAILO_CATEGORIES_SUCCESS:
      return { ...state, loading: false, ramailoCategories: action.payload };

    case GET_RAMAILO_CATEGORIES_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getRamailoPastEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RAMAILO_PAST_EVENTS_REQUEST:
      return { ...state, loading: true };

    case GET_RAMAILO_PAST_EVENTS_SUCCESS:
      return { ...state, loading: false, ramailoPastEvents: action.payload };

    case GET_RAMAILO_PAST_EVENTS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getRamailoSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_RAMAILO_SETTINGS_REQUEST:
      return { ...state, loading: true, ramailoSettings: {} };

    case GET_RAMAILO_SETTINGS_SUCCESS:
      return { ...state, loading: false, ramailoSettings: action.payload };

    case GET_RAMAILO_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        ramailoSettings: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

export const saveRamailoSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_RAMAILO_SETTINGS_REQUEST:
      return { ...state, loading: true };

    case SAVE_RAMAILO_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        saveRamailoSettings: action.payload,
      };

    case SAVE_RAMAILO_SETTINGS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
