import {
  GET_PAST_SUBSCRIPTIONS_FAIL,
  GET_PAST_SUBSCRIPTIONS_REQUEST,
  GET_PAST_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTION_DATA_FAIL,
  GET_SUBSCRIPTION_DATA_REQUEST,
  GET_SUBSCRIPTION_DATA_SUCCESS,
  GET_UPCOMING_SUBSCRIPTIONS_FAIL,
  GET_UPCOMING_SUBSCRIPTIONS_REQUEST,
  GET_UPCOMING_SUBSCRIPTIONS_SUCCESS,
  POST_SUBSCRIPTION_FAIL,
  POST_SUBSCRIPTION_REQUEST,
  POST_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "../constants/types";

export const createEventReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true };

    case POST_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false, createEvent: action.payload };

    case POST_SUBSCRIPTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getPastSubscriptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PAST_SUBSCRIPTIONS_REQUEST:
      return { ...state, loading: true };

    case GET_PAST_SUBSCRIPTIONS_SUCCESS:
      return { ...state, loading: false, pastSubscriptions: action.payload };

    case GET_PAST_SUBSCRIPTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getSubscriptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_REQUEST:
      return { ...state, loading: true };

    case GET_SUBSCRIPTIONS_SUCCESS:
      return { ...state, loading: false, subscriptions: action.payload };

    case GET_SUBSCRIPTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getUpcomingSubscriptionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_UPCOMING_SUBSCRIPTIONS_REQUEST:
      return { ...state, loading: true };

    case GET_UPCOMING_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        upcomingSubscriptions: action.payload,
      };

    case GET_UPCOMING_SUBSCRIPTIONS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getSubscriptionDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_DATA_REQUEST:
      return { ...state, loading: true };

    case GET_SUBSCRIPTION_DATA_SUCCESS:
      return { ...state, loading: false, subscriptionData: action.payload };

    case GET_SUBSCRIPTION_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateSubscriptionDataReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true };

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return { ...state, loading: false, updateSubscription: action.payload };

    case UPDATE_SUBSCRIPTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
