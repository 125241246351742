import {
  GET_PACKAGES_FAIL,
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
} from "../constants/types";

export const getPackagesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PACKAGES_REQUEST:
      return { ...state, loading: true };

    case GET_PACKAGES_SUCCESS:
      return { ...state, loading: false, packagesList: action.payload };

    case GET_PACKAGES_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
