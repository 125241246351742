import * as React from "react";

function Question(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.375 14.875C10.375 15.0821 10.2071 15.25 10 15.25C9.79289 15.25 9.625 15.0821 9.625 14.875C9.625 14.6679 9.79289 14.5 10 14.5C10.2071 14.5 10.375 14.6679 10.375 14.875Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
      />
      <path
        d="M10 11.5004V10.7504C10.5192 10.7504 11.0267 10.5964 11.4584 10.308C11.8901 10.0195 12.2265 9.60957 12.4252 9.12991C12.6239 8.65026 12.6758 8.12246 12.5746 7.61326C12.4733 7.10406 12.2233 6.63633 11.8562 6.26921C11.489 5.9021 11.0213 5.65209 10.5121 5.55081C10.0029 5.44952 9.47511 5.5015 8.99546 5.70018C8.5158 5.89886 8.10583 6.23532 7.81739 6.667C7.52895 7.09867 7.375 7.60619 7.375 8.12537"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export { Question };
