// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event .ant-modal-content {
  border-radius: 20px !important;
  width: 100vw;
  max-width: 1000px;
}
.event .ant-modal-body {
  width: 100vw;
  max-width: 1000px;
  padding: 0;
}
.event .ant-modal {
  display: flex;
  justify-content: center;
}

.event-import .ant-modal-content {
  border-radius: 20px !important;
  width: 100vw;
  max-width: 600px;
}
.event-import .ant-modal-body {
  width: 100%;
  max-width: 600px;
  padding: 0;
}
.event-import .ant-modal {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AACE;EACE,8BAAA;EACA,YAAA;EACA,iBAAA;AAAJ;AAEE;EACE,YAAA;EACA,iBAAA;EACA,UAAA;AAAJ;AAEE;EACE,aAAA;EACA,uBAAA;AAAJ;;AAKE;EACE,8BAAA;EACA,YAAA;EACA,gBAAA;AAFJ;AAIE;EACE,WAAA;EACA,gBAAA;EACA,UAAA;AAFJ;AAIE;EACE,aAAA;EACA,uBAAA;AAFJ","sourcesContent":[".event {\n  .ant-modal-content {\n    border-radius: 20px !important;\n    width: 100vw;\n    max-width: 1000px;\n  }\n  .ant-modal-body {\n    width: 100vw;\n    max-width: 1000px;\n    padding: 0;\n  }\n  .ant-modal {\n    display: flex;\n    justify-content: center;\n  }\n}\n\n.event-import {\n  .ant-modal-content {\n    border-radius: 20px !important;\n    width: 100vw;\n    max-width: 600px;\n  }\n  .ant-modal-body {\n    width: 100%;\n    max-width: 600px;\n    padding: 0;\n  }\n  .ant-modal {\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
