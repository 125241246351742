import { Col } from "antd";
import styled from "styled-components";

export const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlignedCol = styled(Col)`
  display: flex;
  align-items: center;
`;
